<template>
  <div>
    <nav class="navbar">
      <div class="navbar-wrapper">
        <ButtonIcon @click.native="goToPreviousPage" />
        <ProgressIndicator :currentPage="currentStep" :totalPages="totalPages" />
      </div>
    </nav>
    <div class="form-page" v-if="isMissingParams">
      <EmptyState icon-color="red" icon-class="bx bxs-error" :use-link="true"
        :btn-text="$t('empty_state.something_went_wrong.cta')" link-path="/destinations"
        :title="$t('empty_state.something_went_wrong.title')" :text="$t('empty_state.something_went_wrong.text')" />
    </div>
    <BackgroundLayer v-show="showBackgroundLayer" class="fixed" @click.native="closeModals()" />

    <ResetPasswordModal v-if="showResetPassword" :showConfirmation="showResetConfirmation" :showReset="showResetForm"
      class="fixed z-30" @closemodal="closeResetPassword" @goToLogin="closeResetPassword(), openLogin()"
      :show-signup="false" />

    <LoginModal v-show="showLogin" :showSignup="false" class="fixed z-30" @closemodal="closeLogin()"
      @goToforgotPassword="openResetPasswordModal" />

    <form action="" autocomplete="false" class="w-full px-4 pb-48 mt-10 md:px-12" @submit.prevent="submitAction">
      <div class="form-page">
        <h2 class="form-page-title">{{ $t('trip_request.form_6.title') }}</h2>
        <p v-if="!isAuthenticated" class="form-page-sub-title">
          {{ $t("trip_request.form_6.sub_title_1") }}
        </p>

        <p v-else class="form-page-sub-title">
          {{ $t("trip_request.form_6.sub_title_2") }}
        </p>

        <div class="contact">
          <template v-if="!isAuthenticated">
            <p class="mb-4 font-semibold text-black-base">
              {{ $t("trip_request.form_6.login.label") }}
            </p>

            <ButtonBasic :text="`${$t('trip_request.form_6.login.button')}`" class="w-full mb-8 py-3" type="button"
              @click.native="openLogin()" />

            <div class="flex items-center mb-8">
              <Divider />
              <span class="px-4 text-lg text-black-lighter">
                {{ $t("trip_request.form_6.login.alt") }}
              </span>
              <Divider />
            </div>
          </template>

          <p class="mb-2 font-sans font-semibold text-black-base">
            {{ $t("trip_request.form_6.input_1.label") }}
          </p>
          <RadioButtonGroup ref="salutation" v-model="form.salutation" :disabled="loading" :options="options.salutation"
            @blur="$v.form.salutation.$touch()" @change.native="sendSalutation(form.salutation)" />
          <ErrorMessage :error-msg="$t('trip_request.form_6.input_1.error')"
            v-if="$v.form.salutation.$error && !$v.form.salutation.required" />

          <InputText v-model="form.firstName" :disabled="loading" :errorMsg="firstNameErrMsg"
            :label="`${$t('trip_request.form_6.input_2.label')}`"
            :placeholder="`${$t('trip_request.form_6.input_2.placeholder')}`" :readonly="isAuthenticated"
            :showErrMsg="$v.form.firstName.$error" :showSignup="false" class="input-spacing" name="first-name"
            @blur="$v.form.firstName.$touch()" @change.native="sendFirstName(form.firstName)" />

          <InputText v-model="form.lastName" :disabled="loading" :errorMsg="lastNameErrMsg"
            :label="`${$t('trip_request.form_6.input_3.label')}`"
            :placeholder="`${$t('trip_request.form_6.input_3.placeholder')}`" :readonly="isAuthenticated"
            :showErrMsg="$v.form.lastName.$error" name="last-name" class="input-spacing" @blur="$v.form.lastName.$touch()"
            @change.native="sendLastName(form.lastName)" />

          <InputText v-model="form.email" :disabled="loading" :errorMsg="emailErrMsg"
            :label="`${$t('trip_request.form_6.input_4.label')}`"
            :placeholder="`${$t('trip_request.form_6.input_4.placeholder')}`" :readonly="isAuthenticated"
            :showErrMsg="$v.form.email.$error" name="signup-email" type="email" class="input-spacing"
            @blur.native="$v.form.email.$touch()" @change.native="sendEmail(form.email)" />
          <InputPhone v-if="loadInputPhone" v-model="form.phoneNumber" :errorMsg="phoneNumberErrMsg"
            :phoneNumber="form.phoneNumber" :showErrMsg="$v.form.phoneNumber.$error" @blur="$v.form.phoneNumber.$touch()"
            @update="updateNumber" @select="updateNumber" class="input-spacing" />

          <InputText v-if="!isAuthenticated" v-model="form.password" :disabled="loading" :errorMsg="passwordErrMsg"
            :label="`${$t('trip_request.form_6.input_6.label')}`"
            :placeholder="`${$t('trip_request.form_6.input_6.placeholder')}`" :showErrMsg="$v.form.password.$error"
            autocomplete="new-password" class="input-spacing" name="new-password" type="password"
            @blur="$v.form.password.$touch()" />
        </div>
        <ButtonPrimary :loading="loading" :text="$t('trip_request.button')" class="desktop-cta" :disabled="loading" />
      </div>

      <div class="bottom-nav">
        <div class="bottom-nav-wrapper">

          <ButtonPrimary class="block h-12 px-8 w-full" :text="`${$t('trip_request.button')}`" :loading="loading"
            :disabled="loading" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import InputPhone from '@/components/inputs/InputPhone';
import ButtonBasic from '@/components/buttons/ButtonBasic';
import Divider from '@/components/dividers/Divider';
import ButtonIcon from '@/components/buttons/ButtonIcon';
import LoginModal from '@/components/modals/LoginModal';
import BackgroundLayer from '@/components/BackgroundLayer';
import ResetPasswordModal from '@/components/modals/ResetPasswordModal';
import RadioButtonGroup from '@/components/inputs/RadioButtonGroup';
import ErrorMessage from "@/components/ErrorMessage.vue";
import { mapActions, mapGetters } from "vuex";
import EmptyState from "@/components/EmptyState";
import {
  required,
  alpha,
  email,
  minLength,
  requiredIf,
} from 'vuelidate/lib/validators';

export default {
  name: "contact",
  components: {
    ButtonIcon,
    BackgroundLayer,
    ButtonBasic,
    Divider,
    LoginModal,
    RadioButtonGroup,
    ResetPasswordModal,
    InputPhone,
    ErrorMessage,
    EmptyState
  },
  data() {
    return {
      isMissingParams: false,
      loading: false,
      loadInputPhone: false,
      options: {
        salutation: [
          {
            label: this.$t('trip_request.form_6.input_1.values.value_1'),//Mrs.
            name: 'salutation',
            value: "Mrs.",
          },
          {
            label: this.$t('trip_request.form_6.input_1.values.value_2'),//Ms.
            name: 'salutation',
            value: "Ms.",
          },
          {
            label: this.$t('trip_request.form_6.input_1.values.value_3'), //Mr.
            name: 'salutation',
            value: "Mr."
          },
        ],
      },
      form: {
        salutation: undefined,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        consultation: 1,
      },
      showLogin: false,
      showBackgroundLayer: false,
      showResetForm: true,
      showResetConfirmation: false,
      showResetPassword: false,
    };
  },
  validations: {
    form: {
      salutation: {
        required,
      },
      firstName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      lastName: {
        required,
        alpha,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      phoneNumber: {
        required,
      },
      password: {
        reqired: requiredIf(function (form) {
          return !this.isAuthenticated && form.password;
        }),
        minLength: minLength(8),
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('user', ['user']),
    ...mapGetters('request', [
      'travelerDetails',
      'tripDetails',
      'experienceDetails',
      'accommodationDetails',
      'interest',
    ]),
    ...mapGetters({
      currentStep: "request/currentStep",
      totalPages: "request/totalPages",
      isTravelersDetailsPage: "request/isTravelersDetailsPage",
      isTripDetailsPage: "request/isTripDetailsPage",
      isExperiencesPage: "request/isExperiencesPage",
      isInterestPage: "request/isInterestPage",
      isExperience: "request/isExperience",
      isAccommodation: "request/isAccommodation",
      isContactPage: "request/isContactPage",
      firstName: "request/firstName",
      lastName: "request/lastName",
      email: "request/email",
      tripDuration: "request/tripDuration",
      isoStartDate: "request/isoStartDate",
      monthDate: "request/monthDate",
      isoEndDate: "request/isoEndDate",
      phoneNumber: "request/phoneNumber",
      salutation: "request/salutation",
      formData: "request/form",
      isRangeDate: "request/isRangeDate",
      isMonthDate: "request/isMonthDate",
      baseBudgetCurrency: "request/baseBudgetCurrency",
      baseBudget: "request/baseBudget",
      budgetCurrency: "request/budgetCurrency"
    }),
    firstNameErrMsg() {
      let msg;
      if (!this.$v.form.firstName.required) {
        msg = this.$t('trip_request.form_6.input_2.error_1');
      }
      if (!this.$v.form.firstName.minLength) {
        msg = this.$tc(
          'trip_request.form_6.input_2.error_2',
          this.$v.form.firstName.$params.minLength.min,
          { count: this.$v.form.firstName.$params.minLength.min }
        );
      }
      if (!this.$v.form.firstName.alpha) {
        msg = this.$t('trip_request.form_6.input_2.error_3');
      }
      return msg;
    },
    lastNameErrMsg() {
      let msg;
      if (!this.$v.form.lastName.required) {
        msg = this.$t('trip_request.form_6.input_3.error_1');
      }
      if (!this.$v.form.lastName.minLength) {
        msg = this.$tc(
          'trip_request.form_6.input_3.error_2',
          this.$v.form.lastName.$params.minLength.min,
          { count: this.$v.form.lastName.$params.minLength.min }
        );
      }
      if (!this.$v.form.lastName.alpha) {
        msg = this.$t('trip_request.form_6.input_3.error_3');
      }
      return msg;
    },
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t('trip_request.form_6.input_4.error_1');
      }
      if (!this.$v.form.email.required) {
        msg = this.$t('trip_request.form_6.input_4.error_2');
      }
      return msg;
    },
    phoneNumberErrMsg() {
      let msg;

      if (!this.$v.form.phoneNumber.required) {
        msg = this.$t('trip_request.form_6.input_5.error');
      }
      return msg;
    },
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t('trip_request.form_6.input_6.error_1');
      }
      if (!this.$v.form.password.minLength) {
        msg = this.$tc(
          'trip_request.form_6.input_6.error_2',
          this.$v.form.password.$params.minLength.min,
          { count: this.$v.form.password.$params.minLength.min }
        );
      }
      return msg;
    },
  },
  methods: {
    ...mapActions({
      setSalutation: "request/setSalutation",
      increasePage: "request/increasePage",
      decreasePage: "request/decreasePage",
      setFirstName: "request/setFirstName",
      setLastName: "request/setLastName",
      setEmail: "request/setEmail",
      setPhoneNumber: "request/setPhoneNumber",
      setThankYouPage: "request/setThankYouPage",
      setFormData: "request/setFormData"
    }),
    ...mapActions("auth", ["register"]),
    ...mapActions("request", ["createRequest"]),
    validateURLParams() {
      let q = this.$route.query.q;
      let country = this.$route.query.country;

      if (q === undefined || country === undefined) {
        this.isMissingParams = true;
      } else {
        this.isMissingParams = false;
      }
    },
    routerPush(path) {
      this.$router.push({
        path: path,
        query: {
          q: this.$route.query.q,
          country: this.$route.query.country,
          lang: this.$route.query.lang,
        },
      });
    },
    sendSalutation(salutation) {
      let radioBtns = this.$refs.salutation.$el.childNodes;
      for (let item of radioBtns) {
        let radioBtn = item.childNodes[0];
        if (radioBtn.value === salutation) {
          this.setSalutation(salutation);
        }
      }
    },
    sendFirstName(firstName) {
      this.setFirstName(firstName);
    },
    sendLastName(lastName) {
      this.setLastName(lastName);
    },
    sendEmail(email) {
      this.setEmail(email);
    },
    updateNumber(value) {
      this.form.phoneNumber = value;
      this.setPhoneNumber(value);
    },
    openLang() {
      this.showLang = !this.showLang;
      this.showMobileNav = false;
      this.showBackgroundLayer = true;
      this.toggleBodyOverflow();
    },
    closeLogin() {
      this.showLogin = false;
      this.showBackgroundLayer = false;
    },
    closeModals() {
      this.showLang = false;
      this.showBackgroundLayer = false;
      this.closeResetPassword();
      this.closeLogin();
      document.body.classList.remove("overflow-y-hidden");
    },
    openLogin() {
      this.showLogin = true;
      this.showBackgroundLayer = true;
    },
    openResetPasswordModal() {
      this.showLogin = false;
      this.showResetPassword = true;
    },
    closeResetPassword() {
      this.showResetPassword = false;
      this.showResetConfirmation = false;
      this.showResetForm = true;
      this.showBackgroundLayer = false;
    },
    isFormInvalid() {
      this.$v.form.$touch();
      return this.$v.form.$invalid;
    },
    async submits() {
      try {
        if (this.isFormInvalid() === false) {
          this.loading = true;
          await this.$store.dispatch("tracker/setTripRequestEnd");
          await this.$store.dispatch("tracker/recordTripRequestDuration");
          let duration = await this.$store.getters["tracker/time"].tripRequest.duration; // Get duration
          await this.setFormData(this.form);
          await this.setFormData({ processDuration: duration });

          const res = await this.createRequest();
          if (res) {
            this.loading = false;
            this.$notify({
              type: 'success',
              title: this.$t('request.success'), //Your request has been successfully sent.
            });
            this.setThankYouPage(true);
            this.routerPush("/request/thank-you");
          }
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async registerSubmit() {
      try {
        if (this.isFormInvalid() === false) {
          this.loading = true;
          const data = {
            name: `${this.form.firstName} ${this.form.lastName}`,
            email: this.form.email,
            lang: localStorage.getItem("lang") || "en",
            password: this.form.password,
            password_confirmation: this.form.password,
          };

          const res = await this.register(data);
          if (!res) return false;
          //await this.trackSignUp(data);

          await this.submits();
        }
      } catch (error) {
        this.loading = false;
        let msg = error.message;

        if (msg === "The email has already be taken")
          msg = this.$t("page.trip_request.error.email_already_taken");

        this.$notify({
          type: 'error',
          text: msg,
        });
      }
    },
    async submitAction() {
      if (!this.isAuthenticated) {
        await this.registerSubmit();
      } else {
        await this.submits();
      }
    },
    goToPreviousPage() {
      this.decreasePage();
      if (this.isAccommodation === true)
        this.routerPush('/request/accommodation');
      else this.routerPush('/request/interest');
    },
  },
  beforeCreate() {
    if (this.isTravelersDetailsPage === false) {
      this.routerPush("/request");
    } else if (this.isTripDetailsPage === false) {
      this.routerPush("/request/traveler-details");
    } else if (this.isExperiencesPage === false) {
      this.routerPush("/request/trip-details");
    } else if (this.isInterestPage === false) {
      //Check if experiences where selected at the beginning of the form
      if (this.isExperience === true) {
        this.routerPush('/request/experiences');
      } else {
        this.routerPush('/request/trip-details');
      }
    } else if (this.isAccommodationPage === false) {
      this.routerPush('/request/interest_page');
    } else if (this.isContactPage === false) {
      //Check if accommodation was selected at the beginning of the form
      if (this.isAccommodation === true) {
        this.routerPush('/request/accommodation');
      } else {
        this.routerPush('/request/interest');
      }
    }
  },
  created() {
    this.validateURLParams();
  },
  mounted() {
    //Get salutation value on page refresh or when landing on page
    let salutationBtns = this.$refs.salutation.$el.childNodes;
    let currentSalutation = this.salutation;
    for (let item of salutationBtns) {
      let radioBtn = item.childNodes[0];
      if (radioBtn.value === currentSalutation) {
        radioBtn.checked = true;
      }
    }
    this.form.salutation = currentSalutation;

    if (this.user !== null) {
      this.form = {
        ...this.form,
        email: this.email ? this.email : this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        budgetCurrency: this.budgetCurrency,
        baseBudgetCurrency: this.baseBudgetCurrency,
        baseBudget: this.baseBudget,
        phoneNumber:
          this.user.phone.split(' ').length > 1 //Fallback in case there is no space in the number
            ? this.user.phone.split(' ')[1]
            : this.user.phone.split(' ')[0] || '',
      };
    }

    if (!this.isAuthenticated) {
      this.form.phoneNumber = this.phoneNumber || "";
      this.loadInputPhone = true;
    }

    if (this.isAuthenticated) {
      this.form.phoneNumber = this.phoneNumber === "" ? this.user.phone : this.phoneNumber;
      this.loadInputPhone = true;
    }


    if (this.isRangeDate === true) {
      this.form.startDate = this.isoStartDate;
      this.form.endDate = this.isoEndDate;
      this.form.date = this.form.dateRange = `${this.isoStartDate}|${this.isoEndDate}`;
    }
    
    if (this.isMonthDate === true) {
      this.form.date = `${this.monthDate}, ${this.tripDuration}`;
      this.form.startDate = this.isoStartDate;
      this.form.endDate = this.isoEndDate;
    }
  },
};
</script>

<style scoped>
.field-container {
  @apply flex flex-wrap w-full;
}

.input {
  @apply leading-tight p-3 border rounded focus:border-teal-base focus:ring-teal-lighter focus:ring-2 text-black-base placeholder-black-lightest;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}

.input-spacing {
  @apply mt-8;
}

.navbar {
  @apply top-0 flex flex-wrap items-center w-full h-20 px-4 bg-white md:px-12;
}

.navbar-wrapper {
  @apply relative flex items-center justify-between w-full h-12 max-w-screen-md transform -translate-x-1/2 left-1/2;
}

.form {
  @apply w-full px-4 pb-48 mt-10 md:px-12;
}

.form-page {
  @apply relative max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.form-page-title {
  @apply font-sans text-xl font-bold sm:text-2xl text-black-base;
}

.form-page-sub-title {
  @apply mb-12 mt-3 font-sans font-medium text-black-lighter;
}

.input-phone {
  @apply rounded border mt-3 h-12 focus:border-teal-base border-grey-dark focus:ring-teal-lighter focus:ring-2 mb-2;
}

.bottom-nav {
  @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
  @apply block sm:hidden;
}

.bottom-nav-wrapper {
  @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
  @apply hidden sm:block h-12 px-8 w-full mt-8;
}
</style>
